import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'components/ui/Button';

const Intro = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const Item = ({ text }) => (
    <div className="flex gap-8">
      <div className="text-justify">
        <span>
          {text}
        </span>
      </div>
      <div className="bg-white rounded-lg rounded-tr-none h-[40px] min-h-[40px] w-[40px] flex justify-center items-center">
        <img src="/icons/arrow_right_black.svg" className='w-5' alt="lemon" />
      </div>
    </div>
  )

  return (
    <div className="h-full flex flex-col justify-center gap-20 px-8">
      <img src="lemon-logo.svg" alt="lemon" />
      <div className="flex flex-col gap-10 text-white">
        <Item text={<Trans i18nKey="home.text01" components={{ bold: <span className="font-bold"/> }}  />} />
        <Item text={<Trans i18nKey="home.text02" components={{ bold: <span className="font-bold"/> }}  />} />
      </div>
      <div className="w-full flex gap-5">
        <Button
          onClick={() => navigate('/regist')}
          text={t('auth.regist')}
          color="white"
        />
        <Button
          onClick={() => navigate('/login')}
          text={t('auth.login')}
        />
      </div>
    </div>
  )
}

export default Intro;