import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/ui/Card';
import { useLocation } from 'react-router-dom';
import { updatePayment } from 'store/payment';
import { useDispatch } from 'react-redux';
import { formatPrice } from 'hooks/format-price';
import NavMenu from 'components/navigation/NavMenu';

const PaymentPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { state } = useLocation();
  const { items } = state;

  const calcTotal = () => {
    let total = 0;

    items.forEach(el => {
      total = total + el.totalPrice
    });

    return formatPrice(total);
  }

  useEffect(() => {
    dispatch(updatePayment(false))
  }, [dispatch])

  return (
    <div className="h-full pt-10 px-8">
      <NavMenu to="/cart" label={t('payment.label')} text={t('payment.text01')} />
      <div className="h-[85%] py-8">
        <Card className="h-full overflow-y-auto">
          <div className="py-5 px-3 flex flex-col gap-3">
            <div className="flex justify-center">
              <img src="/icons/yellow-lemon.svg" alt="lemon" />
            </div>
            <div className="flex flex-col gap-4 text-center">
              <span className="text-lg font-semibold capitalize">
                {t('payment.pending')}
              </span>
              <span>
                {t('payment.text05')}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-full pt-2 px-5">
            {items.map((item, index) =>
              <div key={index} className="flex gap-2 w-full">
                <div className="w-[15%] flex justify-start">
                  <div className="py-3">
                    <img src="/icons/board.svg" alt="lemon" />
                  </div>
                </div>
                <div className="w-[85%] flex flex-col justify-between py-2">
                  <div className="flex">
                    <span className="text-xs">
                      {item.category}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-xs">
                      {item.name}
                    </span>
                    <span className="font-semibold text-xs">
                      {formatPrice(item.price)}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-auto py-6 items-end border-t border-slate-2 flex justify-between font-semibold px-5">
            <span>
              {t('cart.total')}
            </span>
            <span>
              {calcTotal()}
            </span>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default PaymentPage;