import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from 'helpers/api'
import { toast } from "react-toastify";
import { t } from 'i18next';

export const getRequests = createAsyncThunk('request/list', async (params, { dispatch }) => {
  try {
    const response = await api.get("screen02/user/requests/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const getRequestsHistory = createAsyncThunk('request/history', async (params, { dispatch }) => {
  try {
    const response = await api.get("screen02/user/requests/history/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const requestDetails = createAsyncThunk('request/details', async (params, { dispatch }) => {
  try {
    const response = await api.get("screen02/request/details/" + params);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const createRequest = createAsyncThunk('request/create', async (params, { dispatch }) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  try {
    const response = await api.post("screen02/request/create", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const requestAssignLocker = createAsyncThunk('request/locker/assign', async (params, { dispatch }) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  try {
    const response = await api.post("screen02/request/locker/assign", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const requestDeposit = createAsyncThunk('request/deposit', async (params, { dispatch }) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  try {
    const response = await api.post("screen02/request/deposit", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const requestPickup = createAsyncThunk('request/pickup', async (params, { dispatch }) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  try {
    const response = await api.post("screen02/request/pickup", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

export const cancelRequest = createAsyncThunk('request/request', async (params, { dispatch }) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  try {
    const response = await api.post("screen02/request/cancel", params, config);
    if (!response.data.Result) {
      toast.error(t(`api.errors.${response?.data?.Code}`));
    }
    return response?.data;
  } catch (error) {
    toast.error(t('api.errors.error'));
    throw error;
  }
})

const initialState = {
  list: false,
  history: false,
  selected: false,
};

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRequests.fulfilled, (state, action) => {
      state.list = action.payload.Data
    })
    builder.addCase(getRequestsHistory.fulfilled, (state, action) => {
      state.history = action.payload.Data
    })
    builder.addCase(requestDetails.fulfilled, (state, action) => {
      state.selected = action.payload.Data
    })
  }
});

export default requestSlice.reducer;
