import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/ui/Card';
import { updatePayment } from 'store/payment';
import { useDispatch } from 'react-redux';
import NavMenu from 'components/navigation/NavMenu';

const PaymentSuccessPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updatePayment(false))
  }, [dispatch])

  return (
    <div className="h-full pt-10 px-8">
      <NavMenu to="/products" label={t('payment.label')} text={t('payment.text01')} />
      <div className="h-[85%] py-8">
        <Card className="h-full">
          <div className="py-5 px-3 flex flex-col gap-5">
            <div className="flex justify-center">
              <img src="/icons/green-lemon.svg" alt="lemon" />
            </div>
            <div className="flex flex-col gap-4 text-center">
              <span className="text-lg font-semibold capitalize">
                {t('payment.successful')}
              </span>
              <span className="">
                {t('payment.text05')}
              </span>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default PaymentSuccessPage;