import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Card from 'components/ui/Card';
import Button from 'components/ui/Button';
import LatestRequestCard from 'components/cards/LatestRequestCard';
import HorizontalCarousel, { CarouselSkeleton } from 'components/common/HorizontalCarousel';

import { getProductCategories, getServiceCategories } from 'store/category';
import { getRequestsHistory } from 'store/request';

const Homepage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);
  const { products, services } = useSelector(state => state.category);
  const { history } = useSelector(state => state.request);

  useEffect(() => {
    if (!products) dispatch(getProductCategories());
    if (!services) dispatch(getServiceCategories());

    if (user && !history) dispatch(getRequestsHistory(user.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col h-full w-full px-8 space-y-6">
      <Card>
        <div className="py-2 flex gap-2">
          <LatestRequestCard />
        </div>
      </Card>

      <div className='flex flex-col gap-10 overflow-y-auto'>
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-lg font-semibold">
                {t('service.label', { count: 2 })}
              </span>
              <span className="text-xs text-secondary">
                {t('home.text05')}
              </span>
            </div>
            <div>
              <Button
                text={t('general.see-all')}
                size="sm"
                onClick={() => navigate('/services')}
              />
            </div>
          </div>
          <div>
            {!services
              ? <CarouselSkeleton />
              : <HorizontalCarousel items={services} itemType="service" />
            }
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-lg font-semibold">
                {t('product.label', { count: 2 })}
              </span>
              <span className="text-xs text-secondary">
                {t('home.text06')}
              </span>
            </div>
            <div>
              <Button
                text={t('general.see-all')}
                size="sm"
                onClick={() => navigate('/products')}
              />
            </div>
          </div>
          <div>
            {!products
              ? <CarouselSkeleton />
              : <HorizontalCarousel items={products} itemType="product" />
            }
          </div>
        </div>

        <div className='flex justify-between items-end'>
          <div className="flex flex-col">
            <span className="text-lg font-semibold">{t('home.myPurchases')}</span>
            <span className="text-xs text-secondary">{t('home.useServicesPacks')}</span>
          </div>
          <div className='w-fit'>
            <Button
              isDisabled={!user}
              icon='lucide:arrow-right'
              size='sm'
              onClick={() => navigate('/purchases')}
            />
          </div>
        </div>

        <div className='flex justify-between items-end mb-8'>
          <div className="flex flex-col">
            <span className="text-lg font-semibold">{t('home.viewRequests')}</span>
            <span className="text-xs text-secondary">{t('home.accompanyRequests')}</span>
          </div>
          <div className='w-fit'>
            <Button
              isDisabled={!user}
              size='sm'
              icon='lucide:arrow-right'
              onClick={() => navigate('/requests')}
            />
          </div>
        </div>

        {/* </Card> */}
        {/* We can comment this out for now. And perhaps, we can just include a simple Card, that travels to the /requests, and /purchases */}
        {/* <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <div className="flex items-center gap-1">
                <span className="text-lg font-semibold">
                  {t('home.text07')}
                </span>
                {(history && user) &&
                  <span className="text-secondary">
                    ({history.length})
                  </span>
                }
              </div>
              {!user &&
                <span className="text-xs text-secondary">{t('home.loginForHistoryInfo')}</span>
              }
            </div>
            <div>
              <Button
                isDisabled={!user}
                text={t('general.see-all')}
                size="sm"
                onClick={() => navigate('/history')}
              />
            </div>
          </div>
          <div className='pb-4'>
            <RequestHistory history={history} />
          </div>
        </div> */}
      </div>
    </div>
  )
}

// const RequestHistory = ({ history }) => {

//   const { t } = useTranslation();
//   const { user } = useSelector(state => state.auth);

//   return (
//     <div className='flex flex-col gap-y-3'>
//       {(user && history) && history.map((hist, index) => (
// <HistoryCard />
//       ))}
//     </div>
//   )
// }

export default Homepage;