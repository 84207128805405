import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { formatPrice } from 'hooks/format-price';

import Card from 'components/ui/Card';
import Icon from 'components/ui/Icon';
import { removeItemFromCart } from 'store/cart';

const ItemCard = ({ item }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const calcValue = (quantity, total) => {
    let value = (total / quantity);

    return formatPrice(value);
  }

  const remove = (product) => {
    dispatch(removeItemFromCart({ id: product.id, quantityToRemove: product.type === 'service' ? product.quantity : 1, type: product.type }))
  }

  const [open, setOpen] = useState(false);

  return (
    <Card className='py-0'>
      <div className="py-2 flex flex-col gap-2 px-2">
        <div className="flex justify-between">
          <span className="font-medium text-sm">
            {item.category}
          </span>

          {/* Option 1 */}
          <motion.div
            layout
            animate={{ width: !open ? '24px' : '72px' }}
            transition={{ duration: 0.15, ease: 'easeInOut' }}
            className='h-6 flex items-center justify-center bg-slate-200 rounded-lg'
          >
            {!open ? (
              <motion.div
                onClick={() => setOpen(open => !open)}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
              >
                <Icon icon="lucide:x" width="15" />
              </motion.div>
            ) : (
              <div className='flex w-full h-full relative'>
                <motion.div
                  className='flex items-center justify-center w-1/2 rounded-lg'
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.2 }}
                >
                  <Icon icon="lucide:trash-2" width="15" className='text-red-500' onClick={() => remove(item)}/>
                </motion.div>
                
                <div className='absolute left-1/2 translate-x-[-50%] border-r border-slate-300 h-6 w-1' />

                <motion.div
                  className='flex items-center justify-center w-1/2 rounded-lg'
                  onClick={() => setOpen(!open)}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.2 }}
                >
                  <Icon icon="material-symbols:cancel-outline" width="15" />
                </motion.div>
              </div>
            )}
          </motion.div>
          {/* Option 1 */}

        </div>
        <div className="flex gap-2">
          <div className="w-[25%]">
            <div className="px-3.5 py-4 bg-zinc-100 flex justify-center items-center rounded-lg">
              <img src="/icons/board.svg" alt="lemon" width="50" />
            </div>
          </div>
          <div className="w-[60%] flex flex-col justify-center overflow-hidden">
            <div className="flex flex-col gap-1">
              <span className="font-medium text-sm">
                {item.name}
                {item.type === 'service' && <span> ({item.quantity})</span>}
              </span>
              <span className="text-xs text-secondary lowercase">
                {item.type !== 'service'
                  ? calcValue(item.max, item.price) + " " + t('general.by-piece')
                  : formatPrice(item.price) + " " + t('general.by-piece')
                }
              </span>
            </div>
          </div>
          <div className="flex flex-col items-end justify-center gap-1">
            <div className="bg-black rounded rounded-tr-none py-1.5 px-2.5">
              <span className="font-semibold text-white">
                {item.type === 'service'
                  ? formatPrice(item.price * item.quantity)
                  : formatPrice(item.price)
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

// eslint-disable-next-line no-lone-blocks
{/* <div onClick={() => remove(item)}>
  <Icon icon="lucide:x" width="15" />
</div> */}

export default ItemCard;