import React, { useEffect, useState } from 'react';
import NavMenu from 'components/navigation/NavMenu';
import { useTranslation } from 'react-i18next';
import HeaderCard from 'components/cards/HeaderCard';
import Button from 'components/ui/Button';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import Subtitle from 'components/common/Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import { createRequest } from 'store/request';
import { userAddress } from 'store/address';
import AddressModal from 'components/modals/AddressModal';
import Checkbox from 'components/ui/Checkbox';
import Card from 'components/ui/Card';
import Icon from 'components/ui/Icon';

const RequestOptions = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [deposit, setDeposit] = useState('');
  const [delivery, setDelivery] = useState('');

  const [address, setAddress] = useState(false);

  const { id } = useSelector(state => state.purchase);

  const handleNavigate = () => {
    if (deposit === 'locker') {
      navigate('/request/qrcode', { state: { deposit: deposit, delivery: delivery, address: delivery === 'address' ? address.id : false } })
    } else {
      dispatch(createRequest({
        purchase: id,
        deposit: deposit,
        delivery: delivery,
        address: delivery === 'address' ? address.id : false
      }))
        .then((res) => {
          if (res.payload.Result) {
            navigate('/request/success/counter')
          }
        })
    }
  }

  const isSelect = (type, value) => {
    if (type === 'deposit' && value === deposit) {
      return true;
    } else if (type === 'delivery' && value === delivery) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (!id) {
      navigate('/purchases');
    }
  }, [id, navigate])

  const IconButton = ({ icon, text, onClick, value, type }) => (
    <div
      onClick={onClick}
      className={cn(
        'flex justify-center items-center bg-white border rounded-lg shadow-lg rounded-tr-none py-4 w-full',
        {
          'border-black': isSelect(type, value),
          'border-white': !isSelect(type, value)
        }
      )}
    >
      <div className="flex justify-center items-center gap-3">
        <img src={icon} alt="lemon" />
        <span className="font-semibold">
          {text}
        </span>
      </div>
    </div>
  )

  return (
    <div className="flex flex-col h-full pt-10 pb-10 px-8">
      <div>
        <NavMenu label={t('request.label')} text={t('header.request.text')} to="/request/tutorial" />
      </div>

      <div className="pt-10">
        <HeaderCard label={t('request.label') + ' #000000'} text={t('request.options.title')} icon="/icons/laundry-home.svg" />
      </div>

      <div className='flex flex-col overflow-y-auto'>
        <div className='flex flex-col gap-6 pt-10'>
          <Subtitle title={t('request.deposit.method-label')} text={t('request.deposit.method')} />
          <div className="grid grid-cols-2 gap-5">
            <IconButton
              icon="/icons/deposit/locker.svg"
              text={t('request.collection.methods.locker')}
              value="locker"
              type="deposit"
              onClick={() => setDeposit('locker')}
            />
            <IconButton
              icon="/icons/deposit/balcony.svg"
              text={t('request.collection.methods.balcony')}
              value="balcony"
              type="deposit"
              onClick={() => setDeposit('balcony')}
            />
          </div>
          <Subtitle title={t('request.delivery.method-label')} text={t('request.delivery.method')} />
          <div className="grid grid-cols-2 gap-5">
            <IconButton
              icon="/icons/deposit/locker.svg"
              text={t('request.collection.methods.locker')}
              value="locker"
              type="delivery"
              onClick={() => setDelivery('locker')}
            />
            <IconButton
              icon="/icons/deposit/balcony.svg"
              text={t('request.collection.methods.balcony')}
              value="balcony"
              type="delivery"
              onClick={() => setDelivery('balcony')}
            />
            <IconButton
              icon="/icons/deposit/address.svg"
              text={t('request.collection.methods.address')}
              value="address"
              type="delivery"
              onClick={() => setDelivery('address')}
            />
            <div className='col-span-1' />

            {delivery === 'address' && (
              <div className='col-span-2'>
                <ChooseAddress address={address} setAddress={setAddress} />
              </div>
            )}
          </div>
          <div className="pb-4 mt-auto grid grid-cols-2 gap-5">
            <Button
              text={t('general.previous')}
              color="outline"
              onClick={() => navigate('/request/tutorial')}
            />
            <Button
              text={t('general.next')}
              // isDisabled={deposit === '' || delivery === ''}
              isDisabled={(deposit === '' || delivery === '') || (delivery === 'address' && !address)}
              onClick={handleNavigate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const ChooseAddress = ({ setAddress }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.auth);
  const { list } = useSelector(state => state.address);

  const [create, setCreate] = useState(false);
  const [selected, setSelected] = useState(list ? list.find(a => a.default) : false);

  const getAddresses = () => {
    dispatch(userAddress(user.id));
  }

  useEffect(() => {
    !list && getAddresses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, list])

  useEffect(() => {
    if (selected) setAddress(selected);
  }, [selected, setAddress])

  return (
    <React.Fragment>
      <AddressModal show={create} onClose={() => setCreate(false)} reload={getAddresses} />

      <Card className='min-h-12 h-auto w-full p-4 border rounded-sm rounded-tr-none border-slate-100'>
        <Subtitle title={t('address.select')} text={t('address.selectText')} />
        <div className='flex justify-between items-end pt-2'>
          <div className='w-[65%] overflow-hidden pr-4'>
          </div>
          <div className='w-[35%]'>
            <Button size='sm' text={t('address.new')} onClick={() => setCreate(true)} />
          </div>
        </div>
        <div className='border-t w-full border-zinc-200 mt-3' />
        <div className='mt-3'>
          {!list 
          ? <div className='p-3 flex justify-center items-center w-full h-full'><Icon icon='line-md:loading-alt-loop' /></div>
          : list.map((address, index) => (
            <React.Fragment key={index}>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col w-[85%] text-wrap font-medium'>
                  <p>{address.street}</p>
                  <p>{address.zipcode} {address.city}</p>
                </div>
                <div className='w-[15%] flex justify-end'>
                  <Checkbox value={selected === address} onChange={() => setSelected(address)} />
                </div>
              </div>
              {(index !== list.length - 1) && <div className='my-2 border-t border-zinc-100' />}
            </React.Fragment>
          ))}
        </div>
      </Card>
    </React.Fragment>
  )
}

export default RequestOptions;