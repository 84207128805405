import React from 'react';
import cn from 'classnames';

const Card = ({ children, title, className, pulse }) => {
  return (
    <div 
    className={cn(
      'bg-white w-full rounded-lg rounded-tr-none shadow-lg flex flex-col py-2 px-3',
      className, {
      "animate-pulse bg-gray-50 duration-300" : pulse
      }
    )}
    >
      {title &&
        <div>
          <span className="text-xl font-normal">
            {title}
          </span>
        </div>
      }
      {children}
    </div>
  )
}

export default Card;