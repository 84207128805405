import React from 'react';
import { menu } from 'config/menu';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const Footer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);

  const { pathname } = useLocation();

  const handleNavigate = (item) => {
    if (item.label === 'profile') {
      if (user) {
        return navigate(item.url);
      } else {
        return navigate('/login');
      }
    } else {
      navigate(item.url)
    }
  }

  return (
    <div className="w-full h-full px-8 flex items-center justify-center">
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        transition={{ duration: 0.6, type: 'spring', bounce: 0, delay: 0.25 }}
        className="bg-black w-full h-[70%] rounded-xl rounded-tr-none flex justify-between items-center px-8">
        {menu.map((item, index) =>
          <div
            onClick={() => handleNavigate(item)}
            key={index}
            className="flex flex-col gap-1.5 text-xs justify-center items-center"
          >
            <motion.img
              layout
              src={`/icons/nav/${item.icon}${pathname === item.url ? '_fill' : ''}.svg`}
            />

            {pathname === item.url &&
              <motion.span
                key={pathname}
                initial={false}
                layoutId="img"
                className='text-primary flex'
              >
                {t('menu.' + item.label)}
              </motion.span>
            }
          </div>
        )}
      </motion.div>
    </div>
  )
}

export default Footer;