import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'hooks/format-price';
import Button from 'components/ui/Button';
import ItemCard from 'components/cards/ItemCard';
import { purchase, updateIdentifier } from 'store/payment';
import { clearCart } from 'store/cart';
import NavMenu from 'components/navigation/NavMenu';
import { updateLoading } from 'store/app';
import { motion, AnimatePresence } from 'framer-motion';


const CartPage = () => {
  const { t } = useTranslation();

  const { items, amount } = useSelector(state => state.cart);
  const { logged, user } = useSelector(state => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePayment = () => {
    let products = [];

    items.forEach(el => {
      products.push({ id: el.id, quantity: el.quantity, type: el.type });
    });

    dispatch(updateLoading(true));
    dispatch(purchase({ items: products, user: user.id }))
      .then((res) => {
        if (res.payload.Result) {
          navigate('/payment', { state: { items: items } });
          dispatch(updateIdentifier(res.payload.Data.transaction.identifier))
          dispatch(clearCart());
        }
        dispatch(updateLoading(false));
      })
      .catch(() => {
        dispatch(updateLoading(false));
      })
  }

  return (
    <div className="h-full pt-10 flex flex-col gap-5">
      <div className="px-8">
        <NavMenu to="/home" label={t('cart.label')} text={t('payment.text01')} />
      </div>
      <div className="h-[48%] flex flex-col gap-5 overflow-y-scroll px-8">
        <AnimatePresence>
          {items.map((item, index) =>
            <motion.div
              layout
              key={item.id + item.type}
              initial={{ opacity: 1 }}
              exit={{ x: '-100%', opacity: 0 }}
              transition={{ duration: 0.35, type: 'spring', bounce: 0 }}
            >
              <ItemCard item={item} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="h-[1%]" />
      <div className="bg-white h-fit rounded-tl-[75px] pt-8 pb-4 flex flex-col mt-auto px-8 gap-y-8">
        <div className="text-lg text-black font-semibold flex justify-between">
          <span>
            {t('cart.total')} ({items.length}):
          </span>
          <span>
            {formatPrice(amount)}
          </span>
        </div>
        <div className='pt-2'>
          <Button
            text={logged ? t('payment.advance') : t('auth.login-to-continue')}
            onClick={handlePayment}
            isDisabled={!logged || items.length === 0}
          />
        </div>
        <div className="text-xs flex flex-col gap-0.5 text-justify pt-2 ">
          <span>
            {t('payment.text02')}
          </span>
          <span>
            {t('payment.text03')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CartPage;