import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import Lottie from "lottie-react";
import washerAnimation from 'assets/animations/washer_animation.json';
import cleanClothes from 'assets/animations/clean_clothes.json';
import goShopping from 'assets/animations/go_shopping.json';

import { getRequests } from 'store/request';

const LatestRequestCard = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.auth);
  const { list: requests } = useSelector(state => state.request);

  const notIncludedStatuses = [1, 2, 3, 14, 15, 16];

  const [loading, setLoading] = useState(true);
  const [maxState, setMaxState] = useState(false);

  const [title, setTitle] = useState(t('home.text03'));
  const [description, setDescription] = useState(t('home.text04'));
  const [lottieConfig, setLottieConfig] = useState({
    animationData: washerAnimation,
    autoPlay: false,
  })

  useEffect(() => {
    if (user) {
      dispatch(getRequests(user.id))
      .then(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requests) {
      const validRequests = requests.filter(r => !notIncludedStatuses.includes(r.state));

      const maxStateRequest = validRequests.reduce(
        (max, current) => (current.state > max.state ? current : max),
        validRequests[0]
      );

      if (maxStateRequest) setMaxState(maxStateRequest.state); else setMaxState(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requests]);

  useEffect(() => {
    switch (maxState) {
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        setTitle(t('home.text03'));
        setDescription(t('home.text04'));
        setLottieConfig({ animationData: washerAnimation, autoPlay: false });
      break;

      case 12:
        setTitle("Tem um pedido pronto!");
        setDescription("Visite os seus pedidos para saber qual.");
        setLottieConfig({ animationData: cleanClothes, autoPlay: false });
      break;

      default:
        setTitle("Sem pedidos em processo..");
        setDescription("Faça já o seu próximo!");
        setLottieConfig({ animationData: goShopping, autoPlay: true });
        break;

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxState]);

  return (
    loading
      ? <div className='h-20 w-full'>
        <div className="flex flex-col gap-2 justify-center items-center w-full h-full">
          <p className='text-lg font-medium'>{t('general.loading')}</p>
        </div>
      </div>
      : <React.Fragment>
        <Lottie
          className='h-20 w-20'
          {...lottieConfig}
        />
        <div className="flex flex-col gap-2 justify-center">
          <motion.span
            key={title}
            className="text-sm font-semibold">
            {title}
          </motion.span>
          <motion.span
            key={description}
            className="text-xs text-secondary">
            {description}
          </motion.span>
        </div>
      </React.Fragment>
  )
}

export default LatestRequestCard;