import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ProfileItemCard from 'components/cards/ProfileItemCard';
import Button from 'components/ui/Button';
import { logout } from 'store/auth';
import { toast } from 'react-toastify';
import Card from 'components/ui/Card';
import Icon from 'components/ui/Icon';

const Profile = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.auth);

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        toast.success(t('messages.success.auth.logout'));
        navigate('/home')
      })
  }

  return (
    <div className="flex flex-col h-full px-8 flex flex-col gap-4">
      <div className="flex flex-col justify-center items-center gap-3">
        <div className="bg-teal-100 h-28 w-28 rounded-full flex justify-center items-center drop-shadow-lg">

        </div>
        <span className="font-semibold text-lg">
          {user.name}
        </span>
      </div>
      <div className="flex flex-col gap-4 overflow-y-auto">
        <ProfileItemCard icon="/icons/user.svg" text={t('profile.menu.account')} to="/account" />
        <ProfileItemCard icon="/icons/bag.svg" text={t('profile.menu.purchases')} to="/purchases" />
        <ProfileItemCard icon="/icons/paper.svg" text={t('profile.menu.requests')} to="/requests" />
        <ProfileItemCard icon="/icons/history.svg" text={t('profile.menu.history')} to="/history" />

        <Card className='mb-6'>
          <div className="flex items-center py-2">
            <div className="w-[20%]">
              <Icon icon='mynaui:logout' />
            </div>
            <div className="w-[65%]">
              <span className="font-semibold text-sm">
                Logout
              </span>
            </div>
            <div className="w-[15%]">
              <Button
              color='danger'
                icon="heroicons:arrow-long-right"
                size="xs"
                onClick={handleLogout}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Profile;