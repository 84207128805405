import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Card from 'components/ui/Card';
import Icon from 'components/ui/Icon';
import Collapse from 'components/ui/Collapse';
import ProductCard from './ProductCard';
import ServiceCard from './ServiceCard';
import { t } from 'i18next';

const CategoryCard = ({ category, type, open = false }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setExpanded(true);
      }, 500);
    }
  }, [open])

  const duration = category.products?.length
    ? Math.min(Math.max(0.2, 0.05 * category.products.length), 2)
    : category.services?.length
      ? Math.min(Math.max(0.2, 0.05 * category.services.length), 2)
      : 0.2;

  return (
    <Card>
      <div
        id={"card" + category.id}
        className="py-3 flex items-center gap-5 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="w-[15%] flex justify-center items-center">
          <img src={`/icons/${type === 'product' ? 'product' : 'service'}/${category.icon}.svg`} alt="lemon" />
        </div>
        <div className="flex justify-start w-[75%] overflow-hidden">
          <span className="font-medium text-xs truncate">
            {category.name}
          </span>
        </div>
        <div className="flex justify-end">
          <motion.span
            animate={{ rotate: expanded ? 90 : 0 }}
            transition={{ duration: 0.2 }}
            className=""
          >
            <Icon icon="lucide:chevron-right" width="15" />
          </motion.span>
        </div>
      </div>
      <Collapse open={expanded} duration={duration}>

        {type === 'product' ? (
          category.products ? (
            <div className="flex flex-col gap-4 py-3">
              {category.products.map((item, index) => (
                <div key={index}>
                  <ProductCard product={item} category={category} />
                </div>
              ))}
            </div>
          ) : (
            <p className='p-3 font-medium text-xs'>{t('category.noProductsAvailable')}</p>
          )
        ) : type === 'service' ? (
          category.services ? (
            <div className="flex flex-col gap-4 py-3">
              {category.services.map((item, index) => (
                <div key={index}>
                  <ServiceCard service={item} category={category} />
                </div>
              ))}
            </div>
          ) : (
            <p className='p-3 font-medium text-xs'>{t('category.noServicesAvailable')}</p>
          )
        ) : null}
      </Collapse>
    </Card>
  );
};

export default CategoryCard;
